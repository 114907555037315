import './App.css';
import Carrossel from './components/Carrossel';
import Header from './components/Header';
import Images from './components/Images';
import Section from './components/Section.js';
import Footer from 'components/Footer';
import Api from 'components/services/Api';
import Cards from 'components/Cards';
import { useEffect, useState } from 'react';

function App() {
  const [carrossel, setCarrossel] = useState();
  const [cards, setCards] = useState();

  useEffect(() => {

    Api.post('/api.php', {
      module: 'getCarrosselCard'
    })
    .then(function (response) {
      setCarrossel(response.data.carrosel) 

      setCards(response.data.cards)
    })
    .catch(function (error) {
      console.log(error)
    })
  }, [])

  return (
    <div className="App">
      <Header />
      <Section>
        <Images src="./images/quadrado.png" alt="quadrado" ></Images>
        <Images src="./images/bolinha.png" alt="bolinha" ></Images>
        <div>
          <Images src="./images/icones/icone 1.png" alt="icone" ></Images>
          <Images src="./images/icones/icone 2.png" alt="icone" ></Images>
          <Images src="./images/icones/icone 3.png" alt="icone" ></Images>
          <Images src="./images/icones/icone 4.png" alt="icone" ></Images>
          <div>
            <p>
              <span>
                BEM-VINDO AO
              </span> 
              <span>
                CAMPUS VIRTUAL
              </span>
              <span>
                DA ESCOLA APAS!
              </span>
              <span>
                Uma plataforma cheia de<br />
                <span>conhecimento</span> que proporciona<br /> 
                uma <span>experiência única<br /> 
                de aprendizado</span>
              </span>
            </p>
          </div>
        </div>
        <div>
          <iframe src='https://www.youtube.com/embed/HDr2e8y0mCw?autoplay=1&mute=1'
            width="560" 
            height="315"
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
            title='Escola Apas'
          />
        </div>
      </Section>
      <Section>
        <Images src="./images/bolinha-amarela.png" alt="bolinha" ></Images>
        <h2>CONHEÇA NOSSAS SOLUÇÕES EDUCACIONAIS</h2>
        {(carrossel) ? <Carrossel carrossel={carrossel} /> : ''}
        
      </Section>
      <Section>
        <Images src="./images/bolinha.png" alt="bolinha" ></Images>
        <Images src="./images/pontinho-verde.png" alt="pontinho" ></Images>
        <h2>CONHEÇA NOSSOS NÚMEROS</h2>
        <main>
          {(cards) ? <Cards cards={cards} /> : ''}
          
        </main>
        
      </Section>
      <Footer />
    </div>
  );
}

export default App;
