import Images from "./Images";
import { AiOutlineUser } from "react-icons/ai";
import '../assets/css/Footer.css';


const Footer = () => {
    return ( 
        <footer>
            <div className="container">
                <div>
                    <Images src="./images/bolinha-amarela.png" alt="bolinha" />
                </div>
                <div>
                    <div>
                        <span>QUERO ME ASSOCIAR:</span>
                        <span><AiOutlineUser />(11) 3647-5000</span>
                    </div>
                    <div>
                        <span>CENTRAL DE RELACIONAMENTO APAS:</span>
                        <span><AiOutlineUser />central.apas@apas.com.br</span>
                    </div>
                </div>
                <div>
                    <div>
                        <span>HORÁRIO DE ATENDIMENTO:</span>
                        <span>De Segunda a Sexta-feira das 08:30 ás 18:00, exceto feriados.</span>
                    </div>
                    <div>
                        <Images src="./images/logo-apas.png" alt="logo apas" />
                    </div>
                </div>
                <div>
                    <div>
                        <span>SITE:</span>
                        <span><a href="https://www.eadapas.com.br/plataforma/account/" target='_blank' rel="noreferrer">https://portalapas.org.br/</a></span>
                    </div>
                    <div>
                        <span>SUPORTE TÉCNICO:</span>
                        <span><a href="mailto:escola@apas.com.br">escola@apas.com.br</a></span>
                    </div>
                </div>
            </div>
        </footer> 
    );
}
 
export default Footer;