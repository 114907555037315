import { useEffect, useState } from 'react';
import '../assets/css/Card.css';

const Card = ({prenumero, posnumero,numero,icon,text}) => {
    const [count, setCount] = useState("0");
    useEffect(() => {
        let limit = 0;
        window.addEventListener('scroll', () => {
            if(window.scrollY >= 800 && limit === 0){
                limit++
                let start = 0;
                // first three numbers from props
                const end = parseInt(numero.substring(0,3))
                // if zero, return
                if (start === end) return;
            
                // find duration per increment
                let totalMilSecDur = 1;
                let incrementTime = (totalMilSecDur / end) * 1000;

                let timer = setInterval(() => {
                    start += 1;
                    setCount(String(start) + numero.substring(3))
                    if (start === end) clearInterval(timer)       
                }, incrementTime);
            }
        })
        // dependency array
    }, [numero]);    
    return ( 
        <div className='card'>
            <div>{icon}</div>
            <div>{prenumero}{count}{posnumero}</div>
            <div>{text}</div>
        </div>
     );
}
 
export default Card;