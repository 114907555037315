import Images from "./Images";
import { AiOutlineUser } from "react-icons/ai";
import '../assets/css/Header.css';


const Header = () => {
    return ( 
        <header>
            <nav>
                <div className="logoApas">
                    <Images src="./images/tracos.png" alt="Rastro Logo" ></Images>
                    <a href="https://www.eadapas.com.br/plataforma/account/" target='_blank' rel="noreferrer"><Images src="./images/logo.png" alt="Logo Escola APAS" ></Images></a>
                </div>
                <div className="botaoApas">
                    <a href="https://www.eadapas.com.br/plataforma/account/" target='_blank' rel="noreferrer">
                        <button><AiOutlineUser /></button>
                        <button>Área do Aluno</button>
                    </a>
                </div>
            </nav>
        </header> 
    );
}
 
export default Header;