import { GiTeacher } from "react-icons/gi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { TfiWorld } from "react-icons/tfi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { TbCertificate } from "react-icons/tb";
import { FaUserTie } from "react-icons/fa";

import Card from "./Card";

const Cards = ({cards}) => {
     
    return (
        <>
            <Card prenumero="+" posnumero="" numero={cards[0].numero} icon={<GiTeacher />} text={cards[0].text}/>
            <Card prenumero="" posnumero="" numero={cards[1].numero} icon={<FaUserTie />} text={cards[1].text}/>
            <Card prenumero="+" posnumero="" numero={cards[2].numero} icon={<AiOutlineClockCircle />} text={cards[2].text}/>
            <Card prenumero="+" posnumero="" numero={cards[3].numero} icon={<HiOutlineUserGroup />} text={cards[3].text}/>
            <Card prenumero="+" posnumero="" numero={cards[4].numero} icon={<TbCertificate />} text={cards[4].text}/>
            <Card prenumero="+" posnumero="mil" numero={cards[5].numero} icon={<TfiWorld />} text={cards[5].text}/>
        </>
     );
}
 
export default Cards;