import '../assets/css/Section.css';

const Section = ({children}) => {
    return ( 
        <section>
            <div className="container">
            {children}
            </div>
        </section> 
    );
}
 
export default Section;