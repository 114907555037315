import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../assets/css/Carrossel.css";

// import required modules
import { Autoplay, Navigation } from "swiper";

export default function Carrossel({carrossel}) {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper carrossel-xs"
      >
        {carrossel.map(({ image_xs, link }, index) => (
        <SwiperSlide>
            <a href={link} target='_blank' rel="noreferrer"  key={index}>
                <img src={"./images/carrosselImages/"+image_xs} alt="" />
            </a>
        </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper carrossel-lg"
      >
        {carrossel.map(({ image, link }, index) => (
        <SwiperSlide>
            <a href={link} target='_blank' rel="noreferrer"  key={index}>
                <img src={"./images/carrosselImages/"+image} alt="" />
            </a>
        </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
