// import carrosselImage from '../assets/carrosselImages/carrossel-2.png';

const Images = ({src, alt}) => {
    return (
        <>
            <img src={src} alt={alt}></img>
        </>
    )
}

export default Images